export class StoresMap {
  constructor(coords = [0, 0], zoom = 2) {
    this.map = this.createMap(coords, zoom);
    this.featureGroup = L.featureGroup().addTo(this.map);
  }

  createMap(coords, zoom) {
    const map = L.map('map').setView(coords, zoom);

    L.tileLayer('http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png', {
      maxZoom: 18,
      subdomains: ['a', 'b', 'c', 'd', 'e'],
      attribution: [
        '&copy; <a href="http://www.openstreetmap.org/copyright">',
        'OpenStreetMap</a> contributors, &copy; <a href="https://carto.com/attributions">CARTO</a>'
      ].join('')

    }).addTo(map);
    return map;
  }

  createMarker(coord) {
    L.marker(coord).addTo(this.featureGroup);
  }

  clearMarkers() {
    this.featureGroup.clearLayers();
  }

  updateBounds() {
    const bounds = this.featureGroup.getBounds();
    if (Object.keys(bounds).length) this.map.fitBounds(bounds);
  }
}
