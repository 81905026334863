/* eslint-disable max-statements */
export default function getAllUrlParams(url) {
  // get query string from url (optional) or window
  let queryString = url ? url.split('?')[1] : window.location.search.slice(1);
  let urlParams = null;

  if (queryString) {
    // stuff after # is not part of query string, so get rid of it
    queryString = queryString.split('#')[0];
    const arr = queryString.split('&');

    for (let i = 0; i < arr.length; i++) {
      const param = arr[i].split('=');
      urlParams = {};

      // set parameter name and value (use 'null' if empty)
      let paramName = param[0];
      let paramValue = typeof (param[1]) === 'undefined' ? null : param[1];

      // (optional) keep case consistent
      paramName = paramName.toLowerCase();
      if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();

      // if the paramName ends with square brackets, e.g. colors[] or colors[2]
      if (paramName.match(/\[(\d+)?\]$/)) {
        // create key if it doesn't exist
        const key = paramName.replace(/\[(\d+)?\]/, '');
        if (!urlParams[key]) urlParams[key] = [];

        // if it's an indexed array e.g. colors[2]
        if (paramName.match(/\[\d+\]$/)) {
          // get the index value and add the entry at the appropriate position
          const index = (/\[(\d+)\]/).exec(paramName)[1];
          urlParams[key][index] = paramValue;
        }
        else {
          // otherwise add the value to the end of the array
          urlParams[key].push(paramValue);
        }
      }
      if (!paramName.match(/\[(\d+)?\]$/)) {
        // we're dealing with a string
        if (!urlParams[paramName]) {
          // if it doesn't exist, create property
          urlParams[paramName] = paramValue;
        }
        else if (urlParams[paramName] && typeof urlParams[paramName] === 'string') {
          // if property does exist and it's a string, convert it to an array
          urlParams[paramName] = [urlParams[paramName]];
          urlParams[paramName].push(paramValue);
        }
        else {
          // otherwise add the property
          urlParams[paramName].push(paramValue);
        }
      }
    }
  }

  return urlParams;
}
/* eslint-enable max-statements */
