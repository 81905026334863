import { Controller } from 'stimulus';
import $ from 'jquery/dist/jquery.min';

import getAllUrlParams from './helpers';

export default class extends Controller {
  static targets = ['list', 'filterLabel', 'filterOptions'];

  connect() {
    this.mission_id = this.data.get('missionId');
    this.handleFilterOnLoad();
  }

  // place selected filter text on ui label
  handleFilterOnLoad() {
    if (getAllUrlParams() && getAllUrlParams().filter) {
      const txtSelected = $(this.filterOptionsTarget).children('option:selected').text();
      $(this.filterLabelTarget).text(txtSelected);
    }
  }

  // handle select new filter option: change label and filter stores
  handleFilterChange() {
    const selected = $(this.filterOptionsTarget).children('option:selected').val();
    const filterHref = selected === 'all' ? '' : `?filter=${selected}`;
    window.location.href = window.location.href.replace(/[?#].*|$/, filterHref);
  }
}
