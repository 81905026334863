import { Controller } from 'stimulus';
import { useDispatch, useClickOutside } from 'stimulus-use';
import $ from 'jquery/dist/jquery.min';
import flatpickr from 'flatpickr';
import debounce from 'lodash.debounce';

export default class extends Controller {
  static targets = ['typesDropdown',
    'storeChainResults', 'groupsResults',
    'query', 'cityQuery', 'cityResults',
    'storeChainOption', 'groupOption', 'storeChainsNames', 'groupsNames',
    'list', 'empty', 'button', 'label', 'row'];

  initialize() {
    this.fetchCities = debounce(this.fetchCities, 350);
  }

  connect() {
    useClickOutside(this, { element: this.groupOptionTarget });
    useClickOutside(this, { element: this.storeChainOptionTarget });
    useDispatch(this);
    flatpickr('.flatpickr', {
      altInput: true,
      altFormat: 'd-m-Y H:i',
      dateFormat: 'Y-m-d H:i',
      enableTime: true,
      minDate: 'today'
    });
    this.totalUnassignedStores = 0;
    this.selectedStoreChains = [];
    this.selectedGroups = [];
    this.ENTER_KEY_CODE = 13;
  }

  disconnect() {
    this.reset();
  }

  createCitiesList(elements) {
    const list = document.createElement('ul');
    elements.forEach(element => {
      const newRow = document.createElement('li');
      newRow.appendChild(this.createSearchResultItem(element));
      list.appendChild(newRow);
    });
    this.cityResultsTarget.innerHTML = list.innerHTML;
    this.cityResultsTarget.style.display = 'block';
  }

  createSearchResultItem(element) {
    const row = document.createElement('a');
    row.dataset.action = 'click->store-search#selectCity';
    row.dataset.info = element;
    row.innerHTML = element;
    return row;
  }

  handleTextInput(event) {
    if (event.keyCode === this.ENTER_KEY_CODE) {
      event.preventDefault();
      this.searchStores();
    }
  }

  selectCity({ target }) {
    this.selectedCity = target.innerHTML;
    this.cityQueryTarget.value = target.innerHTML;
    this.removeCityResults();
  }


  fetchCities() {
    const url = new URL(this.data.get('cities_url'));
    this.selectedCity = this.cityQuery.value;
    if (!this.cityQuery) {
      this.cityResultsTarget.style.display = 'none';
      return;
    }

    if (this.cityQuery) url.searchParams.append('q', this.cityQuery);

    fetch(url)
      .then(response => response.json())
      .then(response => this.createCitiesList(response));
  }

  selectStoreChain({ target })  {
    this.selectedStoreChains = this.select(target, 'store_chain', this.selectedStoreChains);
  }

  selectGroup({ target }) {
    this.selectedGroups = this.select(target, 'group', this.selectedGroups);
  }

  displayStoreChainsOptions() {
    this.storeChainResultsTarget.style.display = 'block';
  }

  displayGroupsOptions() {
    this.groupsResultsTarget.style.display = 'block';
  }

  uncheckDropdowns() {
    // Uncheck StoreChains and remove from selectedStoreChains
    this.selectedStoreChains.forEach(storeChain => {
      const checkboxToMatch = `input[id=store_chain_${storeChain.id}]`;
      $(this.storeChainResultsTarget).find(checkboxToMatch).prop('checked', false);
    });

    // Uncheck Groups
    this.selectedGroups.forEach(group => {
      const checkboxToMatch = `input[id=group_${group.id}]`;
      $(this.groupsResultsTarget).find(checkboxToMatch).prop('checked', false);
    });
  }


  // select an element from multiselect dropdown
  select(target, prefix, selectedElements) {
    const id = target.id.replace(`${prefix}_`, '');
    const name = target.name;
    const newSelectedElement = { id, name };

    let newSelectedElements = selectedElements;

    if (newSelectedElements.some(el => el.id === id)) {
      newSelectedElements =
        newSelectedElements.filter(el => el.id !== id);
    }
    else {
      newSelectedElements.push(newSelectedElement);
    }

    return newSelectedElements;
  }

  // Close dropdown
  closeOptions({ target }) {
    const el = target.children[0].children[2];

    if (el === this.groupsResultsTarget) {
      this.groupsResultsTarget.style.display = 'none';
      this.updateDropdownLabel(this.selectedGroups, '#totalGroupsSelected', this.groupsNamesTarget);
    }
    else {
      this.storeChainResultsTarget.style.display = 'none';
      this.updateDropdownLabel(this.selectedStoreChains, '#totalStoreChainsSelected', this.storeChainsNamesTarget); // eslint-disable-line max-len
    }
  }

  updateDropdownLabel(selectedElements, elementId, target) {
    const names = selectedElements.map(({ name }) => name);
    target.value = names.toString();

    const totalSelectedEl = $(elementId);
    if (selectedElements.length > 0) {
      totalSelectedEl[0].innerHTML = selectedElements.length;
      totalSelectedEl.removeClass('hide');
    }
    else {
      totalSelectedEl.addClass('hide');
    }
  }

  // fetch stores list
  searchStores() {
    const params = {
      city: this.selectedCity,
      type_id: this.typesDropdownTarget.value,
      q: this.query,
      store_chain_ids: this.selectedStoreChains.map(({ id }) => id),
      groups_ids: this.selectedGroups.map(({ id }) => id)
    };

    this.dispatch('fetch-stores', { params });
  }

  clearFilters() {
    this.storeChainResultsTarget.style.display = 'none';
    this.groupsResultsTarget.style.display = 'none';
    this.cityResultsTarget.style.display = 'none';

    this.typesDropdownTarget.value = '';
    this.cityQueryTarget.value = '';
    this.queryTarget.value = '';

    this.uncheckDropdowns();
    this.resetFilters();
  }

  resetFilters() {
    this.selectedStoreChains = [];
    this.selectedCity = '';
    this.selectedGroups = [];
  }

  // private
  reset() {
    this.resultsTarget.style.display = 'none';
    this.resultsTarget.innerHTML = '';
    this.queryTarget.value = '';
  }

  removeCityResults() {
    this.cityResultsTarget.style.display = 'none';
    this.cityResultsTarget.innerHTML = '';
  }

  removeEmpty() {
    $(this.emptyTarget).addClass('hide');
  }

  addEmpty() {
    $(this.emptyTarget).removeClass('hide');
  }

  get query() {
    return this.queryTarget.value;
  }

  get cityQuery() {
    return this.cityQueryTarget.value;
  }

  get PlaceTypeId() {
    return this.typesDropdownTarget.id;
  }

  abortPreviousFetchRequest() {
    if (this.abortController) {
      this.abortController.abort();
    }
  }
}
