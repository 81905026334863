import { Controller } from 'stimulus';
import debounce from 'lodash.debounce';

export default class extends Controller {
  static targets = ['query', 'selected', 'sample', 'empty', 'noMatch'];

  initialize() {
    this.fetchResults = debounce(this.fetchResults, 350);
  }

  connect() {
    this.selectedIDs = this.previousStoreIDs;
    if (this.selectedIDs.length === 0) {
      $(this.emptyTarget).removeClass('hide');
    }
    this.storeNodeSample = this.sampleTarget.cloneNode(true);
    this.noMatch = this.noMatchTarget.cloneNode(true);
    this.sampleTarget.remove();
    this.noMatchTarget.remove();
  }

  disconnect() {
    this.reset();
  }

  fetchResults() {
    // don't search if the box is empty
    if (this.query === '') {
      this.reset();
      return;
    }

    const url = new URL(this.data.get('url'));
    url.searchParams.append('query', this.query);

    fetch(url)
      .then(response => response.text())
      .then(html => {
        this.createSearchResultsList(JSON.parse(html));
      })
      .catch(() => {});
  }

  createSearchResultItem(element) {
    const row = document.createElement('a');
    row.id = element.id;
    row.dataset.action = 'click->groups#select';
    row.dataset.info = JSON.stringify(element);
    row.innerHTML = element.name;
    if (element.internal_reference_code != null) {
      row.innerHTML = `(${element.internal_reference_code}) ${row.innerHTML}`;
    }
    return row;
  }

  select({ target }) {
    const store = JSON.parse(target.dataset.info);
    const storeId = (store.store_id ? `store_${store.store_id}` : `place_${store.place_id}`);
    if (!this.selectedIDs.includes(storeId)) {
      const div = this.createNewStoreRow(store, storeId);
      if (this.selectedIDs.length === 0) {
        this.removeEmpty();
      }
      this.selectedTarget.appendChild(div);
      this.selectedIDs.push(storeId);
      this.reset();
    }
  }

  createNewStoreRow(store, storeId) {
    const newElement = this.storeNodeSample.cloneNode(true);
    $(newElement)
      .attr('id', (_, id) => id.replace('_sample', `_${storeId}`))
      .find("[id*='_sample']").attr('id', (_, id) => id.replace('_sample', `_${storeId}`));
    $(newElement)
      .removeClass('hide')
      .find('.store-sample').removeClass('store-sample').addClass('store-independent');
    $(newElement).find('.store-title').text(store.name);
    $(newElement).find('.store-info').text(`${store.address || '--'}`);
    $(newElement).find("input[type='hidden']").val(storeId);

    return newElement;
  }

  unselect({ target: { id } }) {
    const parsedID = id.split('destroy_')[1];
    if (this.selectedIDs.includes(parsedID)) {
      const oldDiv = document.getElementById(`selected_${parsedID}`);
      this.selectedTarget.removeChild(oldDiv);
      this.removeSelectedID(parsedID);
      if (this.selectedIDs.length === 0) {
        this.addEmpty();
      }
    }
  }


  reset() {
    this.queryTarget.value = '';
    // this.previousQuery = null
  }

  removeEmpty() {
    $(this.emptyTarget).addClass('hide');
  }

  addEmpty() {
    $(this.emptyTarget).removeClass('hide');
  }

  get query() {
    return this.queryTarget.value;
  }

  get previousStoreIDs() {
    return JSON.parse(this.data.get('previous-store-ids'));
  }

  removeSelectedID(idToRemove) {
    this.selectedIDs = this.selectedIDs.filter((id) => id !== idToRemove);
  }
}
