import { ApplicationController } from 'stimulus-use';
import $ from 'jquery/dist/jquery.min';
import { StoresMap }  from '../../assets/javascript/map';

export default class extends ApplicationController {
  static targets = ['removeStoresButton', 'addStoresButton',
    'totalAssignedStores', 'totalSearchedStores',
    'assignedStores', 'results', 'list', 'empty', 'button', 'label', 'row', 'map'];

  connect() {
    this.selectedStores = JSON.parse(this.data.get('previous-stores'));
    this.totalUnassignedStores = this.data.get('total_stores');
    this.unassignedLocations = JSON.parse(this.data.get('locations'));
    this.searchedStoresSelected = [];
    this.assignedStoresSelected = [];
    this.formName = this.data.get('form-name');
    this.assignedStores = [];
    this.previousAssignedStores = [];
    this.locationsToDisplay = [];
    this.updateAddStoresButton();
    this.updateRemoveStoresButton();
    this.createAssignedStores();
    this.storesMap = new StoresMap();
  }


  fetchStores(e) {
    const url = this.parseParams(e.detail.params);
    fetch(url)
      .then(response => response.json())
      .then(response => this.createSearchResultsList(response));
  }

  parseParams(params) {
    const url = new URL(this.data.get('url'));

    Object.entries(params).forEach(param => {
      if (param[1]) {
        url.searchParams.append(...param);
      }
    });

    return url;
  }


  selectSearchedStore(event) {
    this.selectStore(event, this.searchedStoresSelected, 'searched');
    this.updateAddStoresButton();
  }

  selectAssignedStore(event) {
    this.selectStore(event, this.assignedStoresSelected, 'assigned');
    this.updateRemoveStoresButton();
  }

  selectStore(event, selectedStores, storesStatus) {
    const eventId = event.target.id;
    const storeName = event.target.name;
    const isChecked = event.target.checked;

    const newAssignedStore = { id: eventId, name: storeName };
    let newSelectedStores = selectedStores;


    if (isChecked) {
      newSelectedStores.push(newAssignedStore);
    }
    else {
      newSelectedStores =
        newSelectedStores.filter(el => el.id !== eventId);
    }

    if (storesStatus === 'assigned') {
      this.assignedStoresSelected = newSelectedStores;
    }
    else {
      this.searchedStoresSelected = newSelectedStores;
    }
  }

  updateAddStoresButton() {
    if (this.searchedStoresSelected.length === 0) {
      this.addStoresButtonTarget.disabled = true;
    }
    else {
      this.addStoresButtonTarget.disabled = false;
    }


    this.addStoresButtonTarget.innerText =
      this.addStoresButtonTarget.innerText.replace(/\d+/g, this.searchedStoresSelected.length);
  }

  updateRemoveStoresButton() {
    if (this.assignedStoresSelected.length === 0) {
      this.removeStoresButtonTarget.disabled = true;
    }
    else {
      this.removeStoresButtonTarget.disabled = false;
    }

    this.removeStoresButtonTarget.innerText =
      this.removeStoresButtonTarget.innerText.replace(/\d+/g, this.assignedStoresSelected.length);
  }

  assignStores() {
    this.totalUnassignedStores -= this.searchedStoresSelected.length;
    this.totalStoresResults = this.searchedStoresSelected.length;

    this.assignedStores.push(...this.searchedStoresSelected);
    $('#selectAllSearchedStores').prop('checked', false);
    if (this.hasMapTarget) this.addLocations(this.searchedStoresSelected);
    this.searchedStoresSelected.forEach(store => {
      this.createNewAssignedStore(store);
      // Check if a selected store from the current search is already assigned
      // If not, add it to "previousAssignedStores"
      // "previousAssignedStores" saves the stores that will be displayed on the left
      if (!this.previousAssignedStores.some(assignedStore => assignedStore.id === store.id)) {
        this.previousAssignedStores.push(store);
      }
    });
    this.removeSelectedStores(this.searchedStoresSelected, this.resultsTarget);
    this.searchedStoresSelected = [];
    this.updateAddStoresButton();
    this.updateStoresLabels();
  }

  unassignStores() {
    this.totalUnassignedStores += this.assignedStoresSelected.length;
    this.assignedStores =
      this.assignedStores.filter(el => !this.assignedStoresSelected.includes(el));
    $('#selectAllAssignedStores').prop('checked', false);
    if (this.hasMapTarget) this.updateLocations();
    this.assignedStoresSelected.forEach(store => {
      if (this.selectedStores.some(selectedStore => selectedStore.id === store.id)) {
        this.selectedStores =
          this.selectedStores.filter(selectedStore => selectedStore.id === store.id);
      }
      if (this.previousAssignedStores.some(assignedStore => assignedStore.id === store.id)) {
        this.createNewUnassignedStore(store);
        this.previousAssignedStores =
          this.previousAssignedStores.filter(assignedStore => assignedStore.id !== store.id);
      }
      this.assignedStores =
        this.assignedStores.filter(assignedStore => assignedStore.id !== store.id);
    });
    this.removeSelectedStores(this.assignedStoresSelected, this.assignedStoresTarget);
    this.assignedStoresSelected = [];
    this.updateRemoveStoresButton();
    this.updateStoresLabels();
  }

  removeSelectedStores(selectedStores, target) {
    selectedStores.forEach(store => {
      const inputToMatch = `input[id=${store.id}]`;
      $(target).find(inputToMatch).parent().parent().remove();
    });
  }


  selectAllSearchedStores({ target }) {
    this.selectAllStores(target, this.resultsTarget, this.searchedStoresSelected, 'searched');
    this.updateAddStoresButton();
  }

  selectAllAssignedStores({ target }) {
    this.selectAllStores(target, this.assignedStoresTarget, this.assignedStoresSelected, 'assigned'); // eslint-disable-line max-len
    this.updateRemoveStoresButton();
  }

  selectAllStores(target, storesTarget, selectedStores, storesStatus) {
    const isChecked = target.checked;
    let newSelectedStores = selectedStores;

    $(storesTarget).find('input[type=checkbox]').prop('checked', isChecked);
    if (isChecked) {
      $(storesTarget).find('input[type=checkbox]').each(function() {
        const newAssignedStore = { id: this.id, name: this.name };
        if (!newSelectedStores.includes(newAssignedStore)) {
          newSelectedStores.push(newAssignedStore);
        }
      });
    }
    else {
      newSelectedStores = [];
    }


    if (storesStatus === 'assigned') {
      this.assignedStoresSelected = newSelectedStores;
    }
    else {
      this.searchedStoresSelected = newSelectedStores;
    }
  }

  generateStoreId(store) {
    if (store.id) {
      if (store.id.toString().startsWith('store_')) return store.id;
      return `store_${store.id}`;
    }
    else {
      return `place_${store.place_id}`;
    }
  }


  createStoreRow(id, action, element) {
    const storeRow = document.createElement('li');
    storeRow.className = 'mb-6';
    storeRow.innerHTML =
      `<div class="checkbox">
      <input type="checkbox" name="${element.custom_name}" 
        value="${id}" id="${id}"
        data-action="stores-selection#${action}" />
      <label for="${id}">
          <div class="label">${element.custom_name}</div>
        </label>
      </div>`;
    return storeRow;
  }

  // creates the list of results returned by the server search on the UI
  createSearchResultsList(elements) {
    this.searchedStoresSelected = [];
    this.previousAssignedStores = [];
    const list = document.createElement('ul');
    elements.forEach(element => {
      const storeId = this.generateStoreId(element); // store_123 or place_123
      // Don't display stores already assigned
      if (this.assignedStores.some(store => store.id === storeId) ||
        this.selectedStores.some(store => this.generateStoreId(store.id) === storeId)) {
        this.previousAssignedStores.push({ id: storeId, name: element.custom_name });
        this.updateAssignedStores();
        return;
      }
      const newRow = this.createStoreRow(storeId, 'selectSearchedStore', element);
      list.appendChild(newRow);
    });
    this.resultsTarget.innerHTML = list.innerHTML;
    this.resultsTarget.style.display = 'block';
    this.updateAddStoresButton();
    this.updateStoresLabels();
  }

  // remove stores from assigned list
  createAssignedStores() {
    this.selectedStores.forEach(store => {
      const storeId = this.generateStoreId(store);
      const newStore = { id: storeId, name: store.name };

      this.assignedStores.push(newStore);
      this.previousAssignedStores.push(newStore);
      this.createNewAssignedStore(newStore);
      this.updateStoresLabels();
    });
  }

  // update pills
  updateStoresLabels() {
    this.totalSearchedStoresTarget.innerHTML =
      `${this.resultsTarget.children.length} de ${this.totalUnassignedStores} Lojas`;
    this.totalAssignedStoresTarget.innerHTML =
      `${this.previousAssignedStores.length} de ${this.assignedStores.length} Lojas`;
  }

  updateAssignedStores() {
    const list = document.createElement('ul');
    this.previousAssignedStores.forEach(assignedStore => {
      const newStoreRow = this.createAssignedStoreRow(assignedStore);
      list.appendChild(newStoreRow);
    });
    this.assignedStoresTarget.innerHTML = list.innerHTML;
  }


  createNewUnassignedStore(element) {
    const newRow = this.createStoreRow(element.id, 'selectSearchedStore', element);
    this.resultsTarget.appendChild(newRow);
    this.resultsTarget.style.display = 'block';
  }

  createNewAssignedStore(element) {
    const newRow = this.createAssignedStoreRow(element);
    this.assignedStoresTarget.appendChild(newRow);
    this.assignedStoresTarget.style.display = 'block';
  }

  createAssignedStoreRow(element) {
    const newRow = document.createElement('li');
    newRow.className = 'mb-6';
    newRow.innerHTML =
      `<div class="checkbox">
        <input multiple="multiple" type="hidden" 
          name="[place_and_store_ids][]" id="place_and_store_ids" value="${element.id}">
        <input type="checkbox" name="${element.name}" 
          value="${element.id}" id="${element.id}" 
          data-action="stores-selection#selectAssignedStore" />
        <label for="${element.id}">
          <div class="label">${element.name}</div>
        </label>
      </div>`;
    return newRow;
  }

  addLocations(selectedStores) {
    selectedStores.forEach(selectedStore => {
      const store = this.unassignedLocations.find(location => location.id === selectedStore.id);
      const coords = this.parseLocation(store.location);
      this.locationsToDisplay.push({ id: store.id, coords });
    });

    this.updateMap();
  }

  updateLocations() {
    this.assignedStoresSelected.forEach(store => {
      this.locationsToDisplay =
        this.locationsToDisplay.filter(location => location.id !== store.id);
    });
    this.updateMap();
  }

  updateMap() {
    this.storesMap.clearMarkers();
    this.locationsToDisplay.forEach(({ coords }) => {
      this.storesMap.createMarker({ lon: coords.lon, lat: coords.lat });
    });
    this.storesMap.updateBounds();
  }



  parseLocation(location) {
    const regExp = /\(([^)]+)\)/;
    const coords = regExp.exec(location)[1];

    const parsedCoords = coords.split(' ');

    return { lon: parsedCoords[0], lat: parsedCoords[1] };
  }
}
