// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'jquery';
import ujs from '@rails/ujs';
import lightbox from 'lightbox2';
import flatpickr from 'flatpickr';
import { Portuguese } from 'flatpickr/dist/l10n/pt.js';

import 'controllers';

flatpickr.localize(Portuguese);
window.lightbox = lightbox;
ujs.start();

