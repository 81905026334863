import { Controller } from 'stimulus';
import debounce from 'lodash.debounce';

export default class extends Controller {
  static targets = ['query', 'results', 'selected', 'sample'];

  initialize() {
    this.fetchResults = debounce(this.fetchResults, 350);
  }

  connect() {
    this.elementNodeSample = this.sampleTarget.cloneNode(true);
    this.sampleTarget.remove();
  }

  disconnect() {
    this.reset();
  }

  fetchResults() {
    // don't search if the box is empty
    if (this.query === '') {
      this.reset();
      return;
    }

    const url = new URL(this.data.get('url'));
    url.searchParams.append('query', this.query);

    fetch(url)
      .then(response => response.text())
      .then(html => {
        this.createList(JSON.parse(html));
      })
      .catch(() => {});
  }

  createList(elements) {
    if (!elements || elements.length === 0) return;

    this.data.fetchResults = elements;

    const list = document.createElement('ul');
    elements.forEach(element => {
      const newRow = document.createElement('li');
      newRow.setAttribute('data-action', 'click->autocomplete#select');
      newRow.setAttribute('id', element.id);
      newRow.innerHTML = element.name;
      list.appendChild(newRow);
    });
    this.resultsTarget.innerHTML = list.innerHTML;
    this.resultsTarget.style.display = 'block';
  }

  select({ target }) {
    const elementId = Number(target.id);
    const element = this.data.fetchResults.find(o => o.id === elementId);
    this.queryTarget.value = element.name;
    const div = this.createNewElementRow(element);
    this.selectedTarget.appendChild(div);
  }

  createNewElementRow(element) {
    const newElement = this.elementNodeSample.cloneNode(true);
    $(newElement)
      .removeClass('hide')
      .find('.autocomplete-sample')
      .removeClass('autocomplete-sample')
      .addClass('autocomplete-independent');
    $(newElement).find('.title').text(element[this.data.get('title')]);
    $(newElement).find('.info').text(`${element[this.data.get('info')] || '--'}`);

    $(this.selectedTarget).find("input[type='hidden']").attr('value', element.id);

    return newElement;
  }

  unselect() {
    $(this.selectedTarget).find("input[type='hidden']").attr('value', null);
    $(this.selectedTarget).children().remove('#element');
    this.reset();
  }


  closeOptions() {
    setTimeout(() => this.resultsTarget.style.display = 'none', 100);
  }

  reset() {
    this.resultsTarget.style.display = 'none';
    this.resultsTarget.innerHTML = '';
    this.queryTarget.value = '';
  }

  get query() {
    return this.queryTarget.value;
  }
}
