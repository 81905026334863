import { Controller } from 'stimulus';
import $ from 'jquery/dist/jquery.min';
import flatpickr from 'flatpickr';
import debounce from 'lodash.debounce';

export default class extends Controller {
  static targets = ['query', 'results', 'list', 'empty', 'sample', 'label', 'noMatch'];

  initialize() {
    this.fetchResults = debounce(this.fetchResults, 350);
  }

  connect() {
    flatpickr('.flatpickr', {
      altInput: true,
      altFormat: 'd-m-Y H:i',
      dateFormat: 'Y-m-d H:i',
      enableTime: true,
      minDate: 'today'
    });
    this.selectedIDs = JSON.parse(this.data.get('previous-store-ids'));
    this.totalStoreCount = Number(this.data.get('previous-store-count'));
    if (this.selectedIDs.length === 0) {
      $(this.emptyTarget).removeClass('hide');
    }
    this.groupNodeSample = this.sampleTarget.cloneNode(true);
    this.noMatch = this.noMatchTarget.cloneNode(true);
    this.sampleTarget.remove();
    this.noMatchTarget.remove();
  }

  disconnect() {
    this.reset();
  }

  fetchResults() {
    // don't search if the box is empty
    if (this.query === '') {
      this.reset();
      return;
    }

    // dont search if the text hasn't changed
    if (this.query === this.previousQuery) {
      return;
    }

    this.previousQuery = this.query;
    const url = new URL(this.data.get('url'));
    url.searchParams.append('query', this.query);

    // TODO: don't include stores that have been selected

    // cancel previous searches if any
    this.abortPreviousFetchRequest();
    this.abortController = new AbortController();

    fetch(url, { signal: this.abortController.signal })
      .then(response => response.text())
      .then(html => {
        this.createSearchResultsList(JSON.parse(html));
      })
      .catch(() => {});
  }

  // creates the list of results returned by the server search on the UI
  createSearchResultsList(elements) {
    const list = document.createElement('ul');
    elements.forEach(element => {
      const newRow = document.createElement('li');
      newRow.innerHTML =
        `<a
           data-action="click->group-search#select"
           data-group-name="${element.name}"
           data-store-count="${element.store_count}"
           data-group-id="${element.id}">${element.name}
         </a>`;
      list.appendChild(newRow);
    });
    this.resultsTarget.innerHTML = list.innerHTML;
    if (elements.length < 1) this.resultsTarget.appendChild(this.noMatch);
    this.resultsTarget.style.display = 'block';
  }

  select({ target }) {
    const groupId = Number(target.dataset.groupId);
    if (!this.selectedIDs.includes(groupId)) {
      const div = this.createNewGroupRow(target.dataset);
      if (this.selectedIDs.length === 0) {
        this.removeEmpty();
      }
      this.listTarget.appendChild(div);
      this.selectedIDs.push(groupId);
      this.reset();
    }
  }


  createNewGroupRow(data) {
    const groupId = data.groupId;
    const groupName = data.groupName;
    const storeCount = data.storeCount;
    const newElement = this.groupNodeSample.cloneNode(true);
    const suffix = Number(storeCount) === 1 ? '' : 's'; // TODO: replace 0 with total stores
    $(newElement)
      .attr('id', (_, id) => id.replace('_sample', `_${groupId}`))
      .find("[id*='_sample']").attr('id', (_, id) => id.replace('_sample', `_${groupId}`));
    $(newElement)
      .removeClass('hide')
      .find('.store-sample').removeClass('store-sample').addClass('store-group');
    $(newElement).find('.store-title').text(groupName);
    $(newElement).find('.store-info').text(
      `${storeCount} ${this.listTarget.dataset.storeLabel}${suffix}`
    );
    $(newElement).find('#mission_store_group_ids').val(groupId);
    return newElement;
  }


  unselect({ target: { id } }) {
    const parsedID = Number(id.split('destroy_store_')[1]);
    if (this.selectedIDs.includes(parsedID)) {
      const oldDiv = document.getElementById(`selected_store_${  parsedID}`);
      this.listTarget.removeChild(oldDiv);
      this.selectedIDs = this.selectedIDs.filter(
        (selectedId) => selectedId !== parsedID
      );
      if (this.selectedIDs.length === 0) {
        this.addEmpty();
      }
    }
  }


  closeOptions() {
    setTimeout(() => this.resultsTarget.style.display = 'none', 200);
  }


  // private
  reset() {
    this.resultsTarget.style.display = 'none';
    this.resultsTarget.innerHTML = '';
    this.queryTarget.value = '';
    this.previousQuery = null;
  }

  removeEmpty() {
    $(this.emptyTarget).addClass('hide');
  }

  addEmpty() {
    $(this.emptyTarget).removeClass('hide');
  }

  abortPreviousFetchRequest() {
    if (this.abortController) {
      this.abortController.abort();
    }
  }


  get query() {
    return this.queryTarget.value;
  }
}
