import { Controller } from 'stimulus';
import $ from 'jquery/dist/jquery.min';

export default class extends Controller {
  static targets = ['row', 'list'];

  connect() {
    this.questionCounter = $('.mission-new-question').length;
    this.questionAnswerOptionCounter = {};
    this.questionNodeSample = this.rowTarget.cloneNode(true);
    this.questionAnswerOptionsSample = $('.new-answer-option')[0].cloneNode(true);
    this.questionOrder = this.highestQuestionOrder();
    this.answerOptionOrder = this.highestAnswerOptionOrder();
    this.resetQuestionClone();
    this.countQuestionAnswerOption();
    this.resetAnswerOptionClone();
    this.disableRemoveMissionQuestion();
    this.disableRemoveQuestionAnswerOption();
    this.patchRemoveAnswerOptionButtonIds();
    this.answerOptionOrder++;
  }

  patchRemoveAnswerOptionButtonIds() {
    $('[id^=new-answer-option]').each((index, answerOption) => {
      const questionOrder = $(answerOption).parent().parent().attr('id').split('-')[1];
      const answerOptionOrder = $(answerOption).find('.input-order input').attr('id').split('_')[7];
      $(answerOption).find('button').prop(
        'id',
        `mission-question-${questionOrder}-answer-option-${answerOptionOrder}-remove`
      );
    });
  }

  countQuestionAnswerOption() {
    const temporaryCounter = {};
    const questionNodes = $('[id^=question]');
    questionNodes.each(function() {
      const questionIndex = $(this).attr('id').split('-')[1];
      temporaryCounter[questionIndex] = 0;
      $(this).children().find('[id^=new-answer-option]').each(() => {
        temporaryCounter[questionIndex]++;
      });
    });
    this.questionAnswerOptionCounter = temporaryCounter;
  }

  highestQuestionOrder() {
    let highest = 0;
    $('.mission-new-question .input-order input').map((idx, el) => {
      highest = Math.max(highest, parseInt(el.value));
    });
    return highest;
  }

  highestAnswerOptionOrder() {
    let highest = 0;
    $('.mission-new-question .answer-options .new-answer-option .input-order input').map(
      (idx, el) => {
        highest = Math.max(highest, parseInt(el.value));
      }
    );
    return highest;
  }

  // controller for when the new question button is pressed
  addNewMissionQuestion() {
    // this block is a hack to change the index in the name and id of the elements.
    // this is needed to convince rails during the post of a nested resource
    const newQuestionNode = this.questionNodeSample.cloneNode(true);
    const tags = [
      { key: 'name', match: '[0]' }, { key: 'id', match: '_0_' }, { key: 'for', match: '_0_' }
    ];

    tags.map(({ key, match }) => {
      $(newQuestionNode).find(`[${key}*='${match}']`).attr(key, (_, counter) => {
        return counter.replace(match, match.replace(/(?!^)[\s\S](?!$)/g, this.questionCounter));
      });
    });

    // Sets default question type to binary
    $(newQuestionNode).find('.new-question-type select')[0].selectedIndex = 0;

    $(newQuestionNode).find('.input-order input')[0].value = ++this.questionOrder;
    // Change question id
    $(newQuestionNode).prop('id', `question-${this.questionCounter}`);
    $($(newQuestionNode).find('.answer-options')[0]).prop(
      'id', `answer-options-${this.questionCounter}`
    );
    $($($($(newQuestionNode).find('.answer-options')[0]).children()[0]).children().last()).prop(
      'id', `mission-question-${this.questionCounter}-answer-option-0-remove`
    );
    this.listTarget.appendChild(newQuestionNode);
    this.enableRemoveMissionQuestion();
    this.countQuestionAnswerOption();
    this.disableRemoveQuestionAnswerOption(this.questionCounter);
    this.questionCounter++;
  }

  addNewQuestionAnswerOption(event) {
    const newAnswerOptionNode = this.questionAnswerOptionsSample.cloneNode(true);
    const questionIndex = $(event.srcElement.parentNode.parentNode).attr('id').split('-')[1];
    this.patchAnswerOptionKeys(newAnswerOptionNode, questionIndex);

    const answerOptionsCounter = this.questionAnswerOptionCounter[questionIndex];
    const answerOptionsDivId = $(event.srcElement.parentNode).attr('id');

    // append cloned Node answer options div before the "Add New Answer Option" button
    $(`#${answerOptionsDivId} > button:nth-of-type(1)`).before(newAnswerOptionNode);
    $(newAnswerOptionNode).find('.input-order input')[0].value = this.answerOptionOrder;

    // add ID to new div
    $(newAnswerOptionNode).prop('id', `new-answer-option-${answerOptionsCounter}`);

    // clear text from new textarea
    $($($(newAnswerOptionNode).children().find('textarea'))[0]).val('');
    // add ID to new div remove button
    $($(newAnswerOptionNode).children()[2]).prop(
      'id', `mission-question-${questionIndex}-answer-option-${this.answerOptionOrder}-remove`
    );

    this.answerOptionOrder++;
    this.enableRemoveQuestionAnswerOption(questionIndex);
    this.countQuestionAnswerOption();
  }

  patchAnswerOptionKeys(node, questionIndex, answerOptionValue = null, isInput = false) {
    const tags = [
      { key: 'name', match: /\[questions_attributes\]\[\d+\]\[answer_options_attributes\]\[\d+\]/ },
      { key: 'id', match: /questions_attributes_\d+_answer_options_attributes_\d+_/ },
      { key: 'for', match: /questions_attributes_\d+_answer_options_attributes_\d+_/ }
    ];

    tags.map(({ key, match }) => {
      let nodes;
      if (isInput)
        nodes = node;
      else
        nodes = $(node).find(`[${key}]`);
      if (match.test($(nodes).attr(key))) {
        $(nodes).attr(key, (_, counter) => {
          if (key === 'name')
            return counter.replace(
              match, `[questions_attributes][${questionIndex}][answer_options_attributes][${
                answerOptionValue == null ? this.answerOptionOrder : answerOptionValue
              }]`
            );
          else
            return counter.replace(
              match, `questions_attributes_${questionIndex}_answer_options_attributes_${
                answerOptionValue == null ? this.answerOptionOrder : answerOptionValue
              }_`
            );
        });
      }
    });
  }

  enableRemoveMissionQuestion() {
    $($(this.rowTarget).first().find('button').last()[0]).prop('disabled', false);
  }

  enableRemoveQuestionAnswerOption(questionID = 0) {
    const answerOptionNodeList = $(`#question-${questionID}`).find('.new-answer-option');
    $($(answerOptionNodeList).last().find('button')[0]).prop('disabled', false);
    if ($(answerOptionNodeList).length !== 1) {
      $($(answerOptionNodeList).first().find('button')[0]).prop('disabled', false);
    }
  }

  disableRemoveMissionQuestion() {
    if ($(this.listTarget).children().length === 1) {
      $($(this.rowTarget).first().find('button').last()[0]).prop('disabled', true);
    }
  }

  disableRemoveQuestionAnswerOption(questionID = 0) {
    const answerOptionNodeList = $(`#question-${questionID}`).find('.new-answer-option');
    if (answerOptionNodeList.length <= 1) {
      $($(answerOptionNodeList).first().find('button')[0]).prop('disabled', true);
    }
  }

  removeMissionQuestion({ target: { id, value } }) {
    const questionOrder = id.split('_')[2];

    if ($(this.listTarget).children().length > 1) {
      $(`#${id}`).parent().remove();

      if (value && $(`input[value=${value}]`).length) {
        $('<input>', {
          type: 'hidden',
          id: `mission_questions_attributes_${questionOrder}_destroy`,
          name: `mission[questions_attributes][${questionOrder}][_destroy]`,
          value: 'true'
        }).appendTo(this.listTarget);
      }
    }
    this.redefineQuestionIds();
    this.countQuestionAnswerOption();
    this.disableRemoveMissionQuestion();
    this.questionCounter--;
  }

  removeQuestionAnswerOption({ target: { id, value } }) {
    const questionOrder = id.split('-')[2];
    const answerOptionOrder = id.split('-')[5];

    const questionNode = $(`#question-${questionOrder}`);

    if ($(questionNode).find('.new-answer-option').length > 1) {
      $(`#${id}`).parent().remove();

      if ((value && $(`input[value=${value}]`).length)) {
        $('<input>', {
          type: 'hidden',
          id: `mission_questions_attributes_${
            questionOrder}_answer_options_attributes_${answerOptionOrder}_destroy`,
          name: `mission[questions_attributes][${
            questionOrder}][answer_options_attributes][${answerOptionOrder}][_destroy]`,
          value: 'true'
        }).appendTo(this.listTarget);
      }
    }

    this.redefineAnswerOptionsIds(questionNode);
    this.disableRemoveQuestionAnswerOption(questionOrder);
    this.countQuestionAnswerOption();
  }

  // Whenever we delete answer options, this will redefine the Ids of the divs and buttons
  redefineAnswerOptionsIds(questionNode) {
    const answerOptionsDiv = $(questionNode).find('.answer-options')[0];
    const questionId = $(questionNode).attr('id').split('-')[1];
    let newAnswerOptionCounter = 0;
    let answerOptionValue = 0;
    let inputCounter = 0;
    $(answerOptionsDiv).children().each((index, childNode) => {
      if ($(childNode).attr('id') && $(childNode).attr('id').startsWith('new-answer-option')) {
        answerOptionValue = $(childNode).find('.input-order input')[0].value;
        $(childNode).prop('id', `new-answer-option-${newAnswerOptionCounter}`);
        $(childNode.children[2]).prop(
          'id', `mission-question-${questionId}-answer-option-${answerOptionValue}-remove`
        );
        this.patchAnswerOptionKeys(childNode, questionId, answerOptionValue);
        newAnswerOptionCounter++;
        inputCounter = 0;
      }
      else if (inputCounter === 0 && $(childNode).prop('tagName').toLowerCase() === 'input') {
        inputCounter++;
        this.patchAnswerOptionKeys(childNode, questionId, answerOptionValue, true);
      }
    });
  }

  // Whenever we delete a question, this will redefine the Ids of the divs and buttons
  redefineQuestionIds() {
    const questionNodes = $('[id^=question]');

    questionNodes.each((index, questionNode) => {
      const questionIndex = $(questionNode).attr('id').split('-')[1];
      $($(questionNode).find('.answer-options')[0]).prop('id', `answer-options-${questionIndex}`);
      this.redefineAnswerOptionsIds(questionNode);
    });
  }

  resetQuestionClone() {
    const qns = $(this.questionNodeSample);
    const eo = qns.find('.new-question-expected .expected-options');
    qns.find('.new-question-image input')[0].value = '';
    qns.find('.new-question-image .preview')[0].style = '';
    qns.find('.new-question-type select')[0].selectedIndex = 0;
    qns.find('.new-question-question textarea')[0].value = '';
    $($(eo[0]).removeClass('hide').find('select')[0]).prop('disabled', false);
    $($(eo[1]).addClass('hide').find('input')[0]).prop('disabled', true);
  }

  resetAnswerOptionClone() {
    const nodeSample = $(this.questionAnswerOptionsSample);
    nodeSample.find('.input-order input')[0].value = '';
    nodeSample.find('.input .textarea textarea')[0].value = '';
  }

  //
  // Toggles the expected_response input between binary and numerical
  //
  handleTypeChange(event) {
    const questionNode = event.srcElement.parentNode.parentNode;
    const elements = $(
      $(event.target).parent().siblings('.new-question-expected')[0]
    ).find('.expected-options');

    // 0 = binary
    // 1 = numeric
    // 2 = multiple_choice
    // 3 = multiple_answer
    // 4 = free_text
    const selectedTypeIndex = $(event.target).parent()[0].children[0].selectedIndex;

    // Remove selected type expected options in order to hide / disable the others
    const selectedType = elements.splice(selectedTypeIndex, 1);

    const answerOptionsForm = $(questionNode).find('.answer-options')[0];
    if ([2, 3].includes(selectedTypeIndex)) { // if is multiple choice or multiple answer
      // enable new answer options form
      $(answerOptionsForm).removeClass('hide');
    }
    else {
      $(answerOptionsForm).addClass('hide');
      // Enable expected options if selected type is not multiple choice
      if ([0, 1].includes(selectedTypeIndex)) { // if is binary or numeric
        $(selectedType).toggleClass('hide');
        $($(selectedType).children()[0]).prop('disabled', false);
      }
    }

    // Disable the others
    for (let i = 0; i < elements.length; i++) {
      $(elements[i]).addClass('hide');
      const innerElement = $(elements[i]).children()[0];
      $(innerElement).prop('disabled', true);
    }
  }

  handleFileChange(event) {
    const file = event.target.files[0] ? URL.createObjectURL(event.target.files[0]) : null;
    const preview = $(event.target).siblings('.preview')[0];
    const label = $(event.target).siblings('label')[0];
    if (file) label.classList.add('noIcon');
    else label.classList.remove('noIcon');
    preview.style.backgroundImage = `url(${file || ''})`;
  }
}
