import { Controller } from 'stimulus';
import $ from 'jquery/dist/jquery.min';

import getAllUrlParams from './helpers';

export default class extends Controller {
  static targets = ['list', 'filterLabel', 'filterOptions'];

  connect() {
    this.mission_id = this.data.get('missionId');
    this.handleFilterOnLoad();
  }

  expandAnswer(event) {
    $(event.target)
      .toggleClass('active')
      .siblings('.answer-data.collapsed, .answer-data.expanded')
      .toggleClass('hide');
  }

  // controller for when the deny/refuse button is pressed on a set of answers
  reject(event) {
    this.post_answer_confirmation('reject', event.target, 'answer-rejected');
  }

  // controller for when the confirm button is pressed on a set of answers
  accept(event) {
    this.post_answer_confirmation('accept', event.target, 'answer-accepted');
  }

  // actually does the post to the server
  post_answer_confirmation(action, button, newRowClass) {
    const buttonsElement = $(button).parent();
    const rowElement = $(buttonsElement).closest('.answer-row');
    const missionStoreId = rowElement.attr('mission-store-id');

    $.ajax({
      type: 'POST',
      url: `/brands/missions/${this.mission_id}/answers/${action}`,
      data: {
        authenticity_token: $('[name="csrf-token"]')[0].content,
        mission_store_id: missionStoreId } })

      .done(() => {
        rowElement.addClass(newRowClass);
        buttonsElement.remove();
      })

      .fail(() => {
        // FIXME: we need to flash error
        // eslint-disable-next-line
        alert('error');
      });
  }

  // place selected filter text on ui label
  handleFilterOnLoad() {
    if (getAllUrlParams() && getAllUrlParams().filter) {
      const txtSelected = $(this.filterOptionsTarget).children('option:selected').text();
      $(this.filterLabelTarget).text(txtSelected);
    }
  }

  // handle select new filter option: change label and filter answers
  handleFilterChange() {
    const selected = $(this.filterOptionsTarget).children('option:selected').val();
    const filterHref = selected === 'all' ? '' : `?filter=${selected}`;
    window.location.href = window.location.href.replace(/[?#].*|$/, filterHref);
  }
}
