import { Controller } from 'stimulus';
import { StoresMap }  from '../../assets/javascript/map';

export default class extends Controller {
  connect() {
    this.initialCoords = [0, 0];
    this.zoom = 2;
    this.storesMap = new StoresMap();
    this.stores = JSON.parse(this.data.get('stores'));
    this.populateMap();
  }

  populateMap() {
    this.stores.forEach(({ location }) => {
      const coords = this.parseLocation(location);
      this.storesMap.createMarker({ lon: coords.lon, lat: coords.lat });
      this.storesMap.updateBounds();
    });
  }

  parseLocation(location) {
    const regExp = /\(([^)]+)\)/;
    const coords = regExp.exec(location)[1];

    const parsedCoords = coords.split(' ');

    return { lon: parsedCoords[0], lat: parsedCoords[1] };
  }
}
