import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['wrapper'];

  connect() {}

  dismiss() {
    this.wrapperTarget.classList.add('hide');
    document.body.classList.remove('has-top-info');
  }
}
